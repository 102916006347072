import { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import LoadPanel from 'devextreme-react/load-panel';

import appInfo from './app-info';
import routes from './app-routes';
import { SideNavOuterToolbar as SideNavBarLayout } from './layouts';

export default function Content() {
  return (
    <SideNavBarLayout title={appInfo.title}>
      <Suspense fallback={<LoadPanel visible={true} />}>
        <Routes>
          {routes.map(({ path, element }) => (
            <Route
              key={path}
              path={path}
              element={element}
            />
          ))}
          <Route
            path="*"
            element={<Navigate to="/home" />}
          />
        </Routes>
      </Suspense>

      {/*
      <Footer>
        <div className="footer-lien">주식회사 리엔</div>
        <div className="footer-flex">
          <div className="footer-addr">
            A <FormattedMessage id="footer.addr" />
          </div>
          <div className="footer-copyright">
            Copyright © {new Date().getFullYear()} {appInfo.title} Inc.
          </div>
        </div>
      </Footer>
       */}
    </SideNavBarLayout>
  );
}
