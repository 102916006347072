import React, { useEffect, useMemo, useRef } from 'react';

import Menu from 'devextreme-react/menu';
import { Item, ItemClickEvent } from 'devextreme/ui/menu';

import { navigation } from '../../app-navigation';
import { useNavigation } from '../../contexts/navigation';

import './top-menu.scss';

export default function TopMenu(props: {
  onItemClick?: (e: ItemClickEvent) => void;
}) {
  const { onItemClick } = props;

  // src\components\side-navigation-menu\SideNavigationMenu.tsx
  function normalizePath() {
    return navigation.map(item => ({
      ...item,
      path: item.path && !/^\//.test(item.path) ? `/${item.path}` : item.path,
    }));
  }

  const items = useMemo(normalizePath, []);

  const {
    navigationData: { currentPath },
  } = useNavigation();

  const menuRef = useRef<Menu>(null);

  useEffect(() => {
    const menu = menuRef.current?.instance;

    if (!menu) {
      return;
    }

    const items = menu.option('items');

    if (!items) {
      return;
    }

    const currentPathIndex = items.findIndex(
      value => (value as Item & { path?: string }).path === currentPath
    );

    if (currentPathIndex === -1) {
      menu.resetOption('selectedItem');

      menu.option(
        'items',
        items.map(value => {
          delete value.selected;
          return value;
        })
      );

      return;
    }

    menu.option('selectedItem', items[currentPathIndex]);

    // 20230321 dkseo 아래 코드는 .option('selectedItem', 다음에 해줘야 제대로 동작
    menu.option(
      'items',
      items.map((value, index) => {
        if (index === currentPathIndex) {
          value.selectable = false;
        }

        return value;
      })
    );
  }, [currentPath]);

  return (
    <React.Fragment>
      <Menu
        ref={menuRef}
        items={items}
        onItemClick={onItemClick}
        selectByClick={true}
        selectionMode="single"
        // width={100}
      />
    </React.Fragment>
  );
}
