import React, { startTransition, useCallback, useState } from 'react';
import { useNavigate } from 'react-router';

import { ButtonTypes } from 'devextreme-react/button';
import { Template } from 'devextreme-react/core/template';
import Drawer from 'devextreme-react/drawer';
import { Item, MenuTypes } from 'devextreme-react/menu';
import { TreeViewTypes } from 'devextreme-react/tree-view';

import { Footer, Header, SideNavigationMenu } from '../../components';
import { useNavigation } from '../../contexts/navigation';
import type { SideNavToolbarProps } from '../../types';
import { useScreenSize } from '../../utils/media-query';
import { useMenuPatch } from '../../utils/patches';

import './side-nav-outer-toolbar.scss';

export default function SideNavOuterToolbar({
  title,
  children,
}: React.PropsWithChildren<SideNavToolbarProps>) {
  // const scrollViewRef = useRef<ScrollView>(null);
  const navigate = useNavigate();
  const { isXSmall, isLarge } = useScreenSize();
  const [patchCssClass, onMenuReady] = useMenuPatch();
  const [menuStatus, setMenuStatus] = useState(MenuStatus.Closed);

  const {
    navigationData: { currentPath },
  } = useNavigation();

  const toggleMenu = useCallback(({ event }: ButtonTypes.ClickEvent) => {
    setMenuStatus(prevMenuStatus =>
      prevMenuStatus === MenuStatus.Closed
        ? MenuStatus.Opened
        : MenuStatus.Closed
    );
    event?.stopPropagation();
  }, []);

  const temporaryOpenMenu = useCallback(() => {
    setMenuStatus(prevMenuStatus =>
      prevMenuStatus === MenuStatus.Closed
        ? MenuStatus.TemporaryOpened
        : prevMenuStatus
    );
  }, []);

  const onOutsideClick = useCallback(() => {
    setMenuStatus(prevMenuStatus =>
      prevMenuStatus !== MenuStatus.Closed && !isLarge
        ? MenuStatus.Closed
        : prevMenuStatus
    );
    return menuStatus === MenuStatus.Closed ? true : false;
  }, [isLarge, menuStatus]);

  const changeHeaderColor = useCallback(() => {
    const header = document.getElementById('header');

    if (header) {
      header.classList.add('header-light');
      header.classList.remove('header-dark');
    }
  }, []);

  const onNavigationChanged = useCallback(
    ({ itemData, event, node }: TreeViewTypes.ItemClickEvent) => {
      if (
        menuStatus === MenuStatus.Closed ||
        !itemData?.path ||
        node?.selected
      ) {
        event?.preventDefault();
        return;
      }

      // Code Splitting
      startTransition(() => {
        navigate(itemData.path);

        changeHeaderColor();
      });

      // scrollViewRef.current?.instance.scrollTo(0);

      if (!isLarge || menuStatus === MenuStatus.TemporaryOpened) {
        setMenuStatus(MenuStatus.Closed);
        event?.stopPropagation();
      }
    },
    [menuStatus, isLarge, navigate, changeHeaderColor]
  );

  const onItemClick = useCallback(
    (e: MenuTypes.ItemClickEvent) => {
      const { itemData, event } = e as MenuTypes.ItemClickEvent & {
        itemData: (Item & { path?: string }) | undefined;
      };

      if (!itemData?.path) {
        event?.preventDefault();
        return;
      }

      if (itemData?.path === currentPath) {
        event?.preventDefault();
        return;
      }

      // Code Splitting
      startTransition(() => {
        if (itemData.path) {
          navigate(itemData.path);

          changeHeaderColor();
        }
      });

      // scrollViewRef.current?.instance.scrollTo(0);

      if (!isLarge || menuStatus === MenuStatus.TemporaryOpened) {
        setMenuStatus(MenuStatus.Closed);
        event?.stopPropagation();
      }
    },
    [changeHeaderColor, currentPath, isLarge, menuStatus, navigate]
  );

  return (
    <div className={'side-nav-outer-toolbar'}>
      <Header
        menuToggleEnabled={isXSmall}
        toggleMenu={toggleMenu}
        title={title}
        onItemClick={onItemClick}
      />

      <Drawer
        className={['drawer', patchCssClass].join(' ')}
        position={'before'}
        closeOnOutsideClick={onOutsideClick}
        openedStateMode={isLarge ? 'shrink' : 'overlap'}
        revealMode={isXSmall ? 'slide' : 'expand'}
        minSize={0}
        maxSize={250}
        shading={isLarge ? false : true}
        opened={menuStatus === MenuStatus.Closed ? false : true}
        template={'menu'}
      >
        <div className={'container'}>
          {/*
          <ScrollView
            ref={scrollViewRef}
            className={'layout-body with-footer'}
            bounceEnabled={false}
          >
           */}
          <div className={'layout-body with-footer'}>
            <div className={'content'}>
              {React.Children.map(children, (item: any) => {
                return item.type !== Footer && item;
              })}
            </div>
            {/*
            <div className={'content-block'}>
              {React.Children.map(children, (item: any) => {
                return item.type === Footer && item;
              })}
            </div>
             */}
          </div>
          {/*
          </ScrollView>
           */}
        </div>

        <Template name={'menu'}>
          <SideNavigationMenu
            compactMode={menuStatus === MenuStatus.Closed}
            selectedItemChanged={onNavigationChanged}
            openMenu={temporaryOpenMenu}
            onMenuReady={onMenuReady}
          ></SideNavigationMenu>
        </Template>
      </Drawer>
    </div>
  );
}

const MenuStatus = {
  Closed: 1,
  Opened: 2,
  TemporaryOpened: 3,
};
