import { useCallback, useEffect, useMemo, useState } from 'react';

import Button from 'devextreme-react/button';
import DropDownButton from 'devextreme-react/drop-down-button';
import Menu from 'devextreme-react/menu';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { ItemClickEvent } from 'devextreme/ui/drop_down_button';

import { useLang } from '../../contexts/lang_provider';
import type { HeaderProps } from '../../types';
import TopMenu from './top-menu';

import './Header.scss';

// assets
import logo from './lien-logo.png';

// navigator.languages = ['ko-KR', 'ko', 'en-US', 'en']/
enum eLang {
  ko = 'ko-KR',
  en = 'en-US',
}

export default function Header({
  menuToggleEnabled,
  title,
  toggleMenu,
  onItemClick,
}: HeaderProps) {
  const { language, setLang } = useLang();

  const [selectedItemKey, setSelectedItemKey] = useState('');

  const onItemClickLang = useCallback(
    (e: ItemClickEvent) => {
      setLang(e.itemData?.id ?? '');
    },
    [setLang]
  );

  const lang = useMemo(() => {
    return [
      { id: eLang.ko, name: 'ko' },
      { id: eLang.en, name: 'en' },
      // "en", "en-US"
    ];
  }, []);

  useEffect(() => {
    setSelectedItemKey(language.startsWith('en') ? eLang.en : eLang.ko);
  }, [lang, language, setSelectedItemKey]);

  const items = useMemo(() => {
    return [{ path: '/home', icon: logo }];
  }, []);

  return (
    <header
      className={'header-component'}
      id="header"
    >
      <Toolbar className={'header-toolbar ln-header-toolbar'}>
        <Item
          cssClass={'menu-button'}
          location={'before'}
          visible={menuToggleEnabled}
          widget={'dxButton'}
        >
          <Button
            icon="menu"
            onClick={toggleMenu}
            stylingMode="text"
          />
        </Item>

        <Item
          cssClass={'header-title'}
          location={menuToggleEnabled ? 'center' : 'before'}
          visible={!!title}
        >
          <Menu
            items={items}
            onItemClick={onItemClick}
          />
        </Item>

        {!menuToggleEnabled && (
          <Item
            // cssClass={''}
            location={'center'}
            // visible={!menuToggleEnabled}
          >
            <TopMenu onItemClick={onItemClick} />
          </Item>
        )}

        {/*
        <Item
          location={'after'}
          locateInMenu={'auto'}
          menuItemTemplate={'userPanelTemplate'}
        >
          <Button
            className={'user-button authorization'}
            height={'100%'}
            stylingMode={'text'}
            width={210}
          >
            <UserPanel menuMode={'context'} />
          </Button>
        </Item>

        <Template name={'userPanelTemplate'}>
          <UserPanel menuMode={'list'} />
        </Template>
         */}

        <Item
          locateInMenu={'auto'}
          location={'after'}
        >
          {/* material ui -> text-transform: uppercase; */}
          <DropDownButton
            className="ln-intl"
            displayExpr="name"
            icon={'globe'}
            items={lang}
            keyExpr="id"
            onItemClick={onItemClickLang}
            selectedItemKey={selectedItemKey}
            showArrowIcon={false}
            splitButton={false}
            stylingMode="text"
            useSelectMode={true}
          />
        </Item>
      </Toolbar>
    </header>
  );
}
