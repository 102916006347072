import { useCallback, useEffect, useState } from 'react';
import { HashRouter as Router } from 'react-router-dom';

import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';

// import './themes/generated/theme.additional.css';

import './dx-styles.scss';
import './ln-styles.scss';

// 20230310 dkseo 주의! App.tsx 는 css 가 위에 있어야 함

import { IntlProvider, MessageFormatElement } from 'react-intl';

import Content from './Content';
import { LangProvider, useLang } from './contexts/lang_provider';
import { NavigationProvider } from './contexts/navigation';
import { useScreenSizeClass } from './utils/media-query';

//
interface FlattenObject {
  [key: string]: string | number | boolean | null;
}

//
type TypeRecord =
  | Record<string, string>
  | Record<string, MessageFormatElement[]>;

function App() {
  /*
  const { user, loading } = useAuth();

  if (loading) {
    return <LoadPanel visible={true} />;
  }

  if (user) {
    return <Content />;
  }

  return (
    <Suspense fallback={<LoadPanel visible={true} />}>
      <UnauthenticatedContent />
    </Suspense>
  );
   */

  const { language } = useLang();

  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState<TypeRecord>();

  // https://localizely.com/blog/react-intl-tutorial/#add-react-intl-dependency
  // https://formatjs.io/docs/getting-started/message-distribution
  const loadLocaleData = useCallback(async (locale: string) => {
    if (locale.startsWith('en')) {
      return import('./lang/en-US.json');
    } else {
      return import('./lang/ko-KR.json');
    }
  }, []);

  const init = useCallback(
    async (language: string) => {
      function flattenObject(obj: any, prefix = ''): FlattenObject {
        return Object.keys(obj).reduce((acc: FlattenObject, k: string) => {
          const pre = prefix.length ? prefix + '.' : '';

          if (typeof obj[k] === 'object' && obj[k] !== null) {
            Object.assign(acc, flattenObject(obj[k], pre + k));
          } else {
            acc[pre + k] = obj[k];
          }

          return acc;
        }, {});
      }

      setLoading(true);

      const messages = await loadLocaleData(language);

      setMessages(flattenObject(messages.default) as TypeRecord);

      setLoading(false);
    },
    [loadLocaleData]
  );

  useEffect(() => {
    init(language);
  }, [init, language]);

  return (
    <IntlProvider
      defaultLocale="ko-KR"
      locale={language}
      messages={messages}
    >
      {!loading && <Content />}
    </IntlProvider>
  );
}

export default function Root() {
  const screenSizeClass = useScreenSizeClass();

  return (
    <Router>
      {/*
      <AuthProvider>
       */}
      <NavigationProvider>
        <div className={`app ${screenSizeClass}`}>
          <LangProvider>
            <App />
          </LangProvider>
        </div>
      </NavigationProvider>
      {/*
      </AuthProvider>
       */}
    </Router>
  );
}
