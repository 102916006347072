import { withNavigationWatcher } from './contexts/navigation';
import {
  AboutPage,
  ContactPage,
  HomePage,
  NewsPage,
  ServicePage,
} from './pages';

const routes = [
  {
    path: '/home',
    element: HomePage,
  },
  {
    path: '/about',
    element: AboutPage,
  },
  {
    path: '/service',
    element: ServicePage,
  },
  {
    path: '/news',
    element: NewsPage,
  },
  {
    path: '/contact',
    element: ContactPage,
  },
];

export default routes.map(route => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path),
  };
});
