import { lazy } from 'react';

/*
export { default as HomePage } from './home/home';
export { default as ProfilePage } from './profile/profile';
export { default as TasksPage } from './tasks/tasks';
 */

// Code Splitting
export const HomePage = lazy(() => import('./home/home'));
export const AboutPage = lazy(() => import('./about/about'));
export const ServicePage = lazy(() => import('./service/service'));
export const NewsPage = lazy(() => import('./news/news'));
export const ContactPage = lazy(() => import('./contact/contact'));
