export const navigation = [
  {
    // icon: 'home',
    path: '/about',
    text: 'About',
  },
  {
    path: '/service',
    text: 'Service',
  },
  {
    path: '/news',
    text: 'News',
  },
  {
    path: '/contact',
    text: 'Contact',
  },
];
