import React, { createContext, useCallback, useContext, useState } from 'react';

export type LangContextType = {
  language: string;
  setLang: (language: string) => void;
};

const LIEN_LANG = 'LIEN_LANG';
const lienLang: string | null = localStorage.getItem(LIEN_LANG);

function LangProvider(props: React.PropsWithChildren<unknown>) {
  const [language, setLanguage] = useState(lienLang ?? navigator.language);

  const setLang = useCallback((language: string) => {
    localStorage.setItem(LIEN_LANG, language);
    setLanguage(language);
  }, []);

  return (
    <LangContext.Provider
      value={{ language, setLang }}
      {...props}
    />
  );
}

const LangContext = createContext<LangContextType>({
  language: lienLang ?? navigator.language,
  setLang: _ => {
    //
  },
});
const useLang = () => useContext(LangContext);

export { LangProvider, useLang };
